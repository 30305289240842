/**
 * Name: Burger
 */

import Collapsible from "@lunaweb/vega-reactor-js/src/patterns/Collapsible";

export default class Burger extends Collapsible {
  static settings = {
    control: '.js-burger',
    target: '.js-navigation',
  };

  constructor (element, options = {}) {
    super(element, {
      ...Burger.settings,
      ...options,
    });
  }

  get isDisplayed () {
    return getComputedStyle(this.element).display !== 'none';
  }

  open () {
    super.open();
    document.querySelector('header').classList.add('is-open');
    document.querySelector('.js-burger-label').innerHTML = "Fermer";
    document.querySelector('html').classList.add('no-scroll');
    document.querySelector('header').ontransitionend = () => {
      document.querySelector('header').scrollTop = 0;
    };
    
    if (document.body.classList.contains("admin-bar")) {
      document.querySelector('header').style.top = `${50}px`;
    }

    this.target.focus();
  }

  close () {
    super.close();
    document.querySelector('header').classList.remove('is-open');
    document.querySelector('header').classList.remove('is-megamenu-open');
    document.querySelector('.js-burger-label').innerHTML = "Menu";
    document.querySelector('html').classList.remove('no-scroll');

    if (document.body.classList.contains("admin-bar")) {
      document.querySelector('header').style.top = `0`;
    }
  }

  mount () {
    super.mount();
    this.target.classList.add('has-burger');
    this.target.setAttribute('tabindex', '-1');

    window.addEventListener('resize', function() {
      const megamenu = document.querySelector('.is-megamenu-open');
      const noscroll = document.querySelector('.no-scroll');
      if (megamenu && megamenu.classList.contains('is-megamenu-open')) {
        megamenu.classList.remove('is-megamenu-open');
      }
      if (noscroll) {
        noscroll.classList.remove('no-scroll');
      }
  });
    

    window.addEventListener('resize', function() {
      const megamenu = document.querySelector('.is-megamenu-open');
      const noscroll = document.querySelector('.no-scroll');
      if (megamenu && megamenu.classList.contains('is-megamenu-open')) {
        megamenu.classList.remove('is-megamenu-open');
      }
      if (noscroll) {
        noscroll.classList.remove('no-scroll');
      }
  });
    
  }

  unmount () {
    super.unmount();
    this.target.classList.remove('has-burger');
    this.target.removeAttribute('tabindex');
  }
}
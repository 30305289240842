import Breadcrumbs from './Breadcrumbs';

document.addEventListener('DOMContentLoaded', () => {
  const breadcrumbsElements = document.querySelectorAll('.js-breadcrumbs');
  if (!breadcrumbsElements.length) {
    return;
  }
  breadcrumbsElements.forEach((element) => {
    const breadcrumbsElement = new Breadcrumbs(element, {
      isResponsive: true,
    });
    breadcrumbsElement.mount();
  });
});

import Burger from './Burger';

document.addEventListener('DOMContentLoaded', () => {

  // init burger 
  const burgerElement = document.getElementById('burger');
  if (!burgerElement) {
    return;
  }
  
  const burger = new Burger(burgerElement, {
    control: burgerElement,
    target: document.getElementById('navigation'),
  });

  // Check Windows width

  function checkWindowsWidth() {
    if (window.innerWidth <= 1234) {
      burger.mount();
    } else {
      burger.unmount();
    }
  }

  checkWindowsWidth();

  window.onresize = function(event) {
    checkWindowsWidth();
    document.querySelector('header').classList.remove('is-open');
  }

});

  

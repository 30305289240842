import Navigation from './Navigation';

document.addEventListener('DOMContentLoaded', () => {
  const navigationElement = document.getElementById('navigation');
  if (!navigationElement) {
    return;
  }
  const navigation = new Navigation(navigationElement);
  navigation.mount();
});

document.addEventListener("DOMContentLoaded", () => {
    // Variable
    const $html = document.documentElement;
    const $body = document.querySelector("body");
    const $header = document.querySelector(".js-header");
    const $toggle = document.querySelector(".js-header-toggle");
    const $main = document.querySelector(".js-main");
  
    const is_admin = $body.classList.contains("admin-bar");
  
    if ($header) {
      // Fonction
    
      let lastScrollTop = 0;
      let scrollingUp = false;
      let lastScrollPos = 0;
      let ticking = false;
    
      function headerState(currentScrollTop) {
        if (scrollingUp) {
          $header.classList.remove("header-hidden");
          if (is_admin && $header) {
                $header.style.top = `${50}px`;
            }
        } else {
          $header.classList.add("header-hidden");
          if (is_admin && $header) {
            $header.style.top = `${0}`;
          }
        }
    
        lastScrollTop = currentScrollTop;
      }
    
      function handleScroll() {
        let scrollPos = window.pageYOffset || document.documentElement.scrollTop;
    
        if (scrollPos >= 140) {
          if (scrollPos >= lastScrollPos) {
            scrollingUp = false;
          } else {
            scrollingUp = true;
          }
    
          headerState(scrollPos);
          lastScrollPos = scrollPos <= 0 ? 0 : scrollPos;
        }
    
        ticking = false;
      }
    
      function requestTick() {
        window.requestAnimationFrame(handleScroll);
        ticking = true;
      }
    
      window.addEventListener("scroll", function () {
        if (!ticking) {
          requestTick();
        }
      });
    
      // vérifier si le header est revenu à sa place
    
      let isSticky = false;
      let isTop = true;
      
      function checkScroll() {
        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      
        if (scrollPosition > 200 && !isSticky) {
          $header.classList.remove("top");
          $header.classList.add("sticky");
          isSticky = true;
          isTop = false;
        } else if (scrollPosition < 140 && !isTop) {
          $header.classList.add("top");
          $header.classList.remove("sticky");
          isTop = true;
          isSticky = false;
          if (is_admin && $header) {
                $header.style.top = `${0}px`;
            }
        }
      
        requestAnimationFrame(checkScroll);
      }
      
      checkScroll();
    }
  
  });
  
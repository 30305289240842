import Details from './Details';

// SEASON DETAIL

document.addEventListener('DOMContentLoaded', () => {
  const detailsElements = document.querySelectorAll('.js-season');
  if (!detailsElements.length) {
    return;
  }

  const detailsArray = Array.from(detailsElements);

  // Gestion par date

  let today = new Date();
  let spring = new Date(today.getFullYear(), 2, 21);
  let summer = new Date(today.getFullYear(), 5, 21);
  let autumn = new Date(today.getFullYear(), 8, 21);
  let winter = new Date(today.getFullYear(), 11, 21);


  if (today < spring && today >= winter) { // Hiver
    detailsElements[3].classList.add('current');
  } else if (today < summer && today >= spring) { // Printemps
    detailsElements[0].classList.add('current');
  } else if (today < autumn && today >= summer) { // Été
    detailsElements[1].classList.add('current');
  } else if (today < winter && today >= autumn) { // Automne
    detailsElements[2].classList.add('current');
  } else {
    detailsElements[0].classList.add('current');
  }

  // Gestion du détail 'current'

  const allDetails = detailsArray.map((element) => {
    const detailsElement = new Details(element, {
      hasAdditionalControl: false,
    });
    detailsElement.mount();
    if (element.classList.contains('current')) {
      detailsElement.open();
    }
    return detailsElement;
  });

  function closeOthers(openElement) {
    allDetails.forEach(details => {
      if (details !== openElement && details.expanded) {
        details.close();
      }
    });
  }

  allDetails.forEach(detailsElement => {
    detailsElement.on('open', () => {
      closeOthers(detailsElement);
    });
  });

});

// TRANSCRIPT SEASON

document.addEventListener('DOMContentLoaded', () => {
  const detailsElements = document.querySelectorAll('.js-transcript');
  if (!detailsElements.length) {
    return;
  }

  const detailsArray = Array.from(detailsElements);

  const allDetails = detailsArray.map((element) => {
    const detailsElement = new Details(element, {
      hasAdditionalControl: false,
    });
    detailsElement.mount();
    return detailsElement;
  });

  function closeOthers(openElement) {
    allDetails.forEach(details => {
      if (details !== openElement && details.expanded) {
        details.close();
      }
    });
  }

  allDetails.forEach(detailsElement => {
    detailsElement.on('open', () => {
      closeOthers(detailsElement);
    });
  });
});
